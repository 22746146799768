import React from "react";
import * as S from "./styles";
import { Button } from "../../Atoms/Button";
import { MainMenu } from "../../Molecules/MainMenu";
import { Footer } from "../../Molecules/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CookieBannerPage } from "../../Atoms/CookieBanner";

export const HomeTemplate = () => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  const navigate = useNavigate();

  return (
    <S.Container>
      <CookieBannerPage />
      <S.Main>
        <MainMenu />
        <S.MainContent>
          <div>
            <h1>
              Log <span>Vistorias</span>
            </h1>
          </div>

          <div>
            <S.Text>
              Somos uma empresa especializada em{" "}
              <S.TextGreen>
                transferência veicular credenciada ao DETRAN/MA.
              </S.TextGreen>{" "}
              Contamos com uma equipe capacitada e com mais de 10 anos de
              experiência.
            </S.Text>
          </div>

          <div>
            <Button onClick={() => navigate("/agendamento")}>
              AGENDAR VISTORIA
            </Button>
          </div>
        </S.MainContent>
      </S.Main>
      <S.AboutSection>
        <S.AboutContent id="sobre">
          <h2>
            O que você encontra
            <br /> na <span>Log Vistorias</span>
          </h2>

          <S.Flex $justifyContent="space-between" $gap="100px">
            <S.card>
              <h3>
                <img
                  src="/assets/imgs/seguranca.svg"
                  alt="icone carro de segurança"
                />
                Segurança
              </h3>
              <p>
                Nossas vistorias garantem sua <span>segurança</span> evitando
                veículos adulterados ou documentação desatualizada.
              </p>
            </S.card>
            <S.card>
              <h3>
                <img src="/assets/imgs/estalo.svg" alt="icone mão" /> Facilidade
              </h3>
              <p>
                Além do <span>rápido agendamento</span> e{" "}
                <span>atendimento online</span>, você terá acesso aos laudos
                emitidos para usar conforme a sua necessidade.
              </p>
            </S.card>
            <S.card>
              <h3>
                <img src="/assets/imgs/dindin.svg" alt="icone dinheiro" />
                Rentabilidade
              </h3>
              <p>
                Com as nossas avaliações veiculares, você tem mais{" "}
                <span>segurança para fazer um bom negócio</span>, seja na compra
                ou venda de seu automóvel.
              </p>
            </S.card>
          </S.Flex>

          {/* <S.WrapperValores>
            <S.card>
              <h3>Missão</h3>
              <S.cardBorder>
                <p>
                  Prover segurança na negociação de <span>veículos usados</span>{" "}
                  e <span>seminovos</span>, dando <span>garantia</span> a quem
                  compra e vende.
                </p>
              </S.cardBorder>
            </S.card>
            <S.card>
              <h3>Visão</h3>
              <S.cardBorder>
                <p>
                  Ser líder no mercado de atuação,{" "}
                  <span>garantindo satisfação</span> dos clientes e
                  colaboradores.
                </p>
              </S.cardBorder>
            </S.card>
            <S.card>
              <h3>Valores</h3>
              <S.cardBorder>
                <ul>
                  <li>RESPEITO</li>
                  <li>QUALIDADE</li>
                  <li>CONFIANÇA</li>
                  <li>VALORIZAÇÃO</li>
                </ul>
              </S.cardBorder>
            </S.card>
          </S.WrapperValores> */}

          {/* <Link to={"/agendamento"}>
            <Button>AGENDAR VISTORIA</Button>
          </Link> */}
        </S.AboutContent>
      </S.AboutSection>

      <S.ServicesSection>
        <S.ServicesContent id="servicos">
          <S.ServiceGroup1>
            <h2>Serviços</h2>
            <S.WrapperContent>
              <h4>Vistoria de Transferência</h4>
              <p>
                O processo de vistoria de transferência veicular consiste no
                preenchimento de amplo checklist, realizado por um profissional
                capacitado e certificado para a atividade.
              </p>

              <a
                id="btn_checklist"
                href="/assets/docs/checklist-log.pdf"
                target="_blank"
              >
                <img src="/assets/imgs/icon-check-dark.svg" alt="icone check" />
                Baixar Checklist
              </a>
            </S.WrapperContent>
          </S.ServiceGroup1>

          <S.ServiceGrid>
            <S.PriceTable>
              <S.PriceTableContent1>
                <h2>
                  Vistoria de Transferência / 1°Emplacamento
                  <span>Posto fixo</span>
                </h2>
              </S.PriceTableContent1>

              <S.PriceTableContent2>
                <div>
                  <S.PriceTableText>Moto / Carro</S.PriceTableText>
                </div>

                <div>
                  <S.PriceTablePrice>
                    R$ 152
                    {/* <span className="priceTableRed">**</span> */}
                  </S.PriceTablePrice>
                </div>
              </S.PriceTableContent2>
              <S.PriceTableContent3>
                <div>
                  <S.PriceTableText>
                    Ônibus / Caminhão e Veículos Grandes
                    <span className="priceTableRedSecundary">*</span>
                  </S.PriceTableText>
                </div>

                <div>
                  <S.PriceTablePrice>
                    R$ 190
                    {/* <span className="priceTableRed">**</span> */}
                  </S.PriceTablePrice>
                </div>
              </S.PriceTableContent3>
            </S.PriceTable>

            <Link to={"/agendamento"}>
              <Button data-variant-dark>AGENDAR VISTORIA</Button>
            </Link>

            <S.TablePriceInloco>
              <div>
                <h2>
                  Vistoria de Transferência / 1°Emplacamento{" "}
                  <span>Domiciliar</span>
                </h2>
              </div>

              <div>
                <div>
                  <S.PriceTablePrice>
                    R$ 190
                    {/* <span className="priceTableRed">**</span> */}
                  </S.PriceTablePrice>
                </div>
              </div>
            </S.TablePriceInloco>

            <div>
              <S.TextRightSide>
                <S.RedItem>*</S.RedItem> Micro-ônibus - Reboque - Semirreboque -
                Caminhão - Caminhão Trator - Trator de Rodas - Trator Misto -
                Chassi/Plataforma
              </S.TextRightSide>

              {/* <S.TextRightSide>
                <S.RedItem>**</S.RedItem>Taxa será acrescida no valor da compra.
                Taxa bancária no <span>Boleto</span> no valor de <span>R$ 3,92</span>.
              </S.TextRightSide> */}
            </div>
          </S.ServiceGrid>
        </S.ServicesContent>
      </S.ServicesSection>
      <S.SectionLocalizations>
        <S.LocalizationsContent id="localizacao">
          <h2>Localização</h2>
          <p>
            Na <span>LOG VISTORIAS</span>, valorizamos a comunicação eficiente
            com nossos clientes. Estamos aqui para ajudar, fornecer informações
            detalhadas e orientá-lo em todo o processo de vistoria. Queremos
            construir relacionamentos confiáveis e garantir sua satisfação.
          </p>

          <S.WrapperCards>
            <S.CardMap>
              <S.ImgMap src="/assets/imgs/map1.png" alt="foto mapa google" />
              <S.TitleMap
                href="https://www.google.com/maps/place/Log+Vistoria+Veicular+-+S%C3%A3o+Lu%C3%ADs/@-2.5444895,-46.5648425,8z/data=!4m7!3m6!1s0x7f68fc1816c212d:0xbe058f0be98b60ba!8m2!3d-2.5444895!4d-44.2577136!15sCh5Mb2cgVmlzdG9yaWEgVmVpY3VsYXIgc2FvIGx1aXOSARZjYXJfaW5zcGVjdGlvbl9zdGF0aW9u4AEA!16s%2Fg%2F11lcr52lxs?entry=tts"
                target="_blank"
              >
                <img
                  src="/assets/imgs/localizacao2.svg"
                  alt="icone pin do mapa"
                />
                SÃO LUÍS/ MA
              </S.TitleMap>
            </S.CardMap>
            <S.CardMap>
              <S.ImgMap src="/assets/imgs/map2.png" alt="foto mapa google" />
              <S.TitleMap
                href="https://www.google.com/maps/place/Log+Vistoria+Veicular+-+Timon/@-3.8189542,-44.8647502,8z/data=!4m7!3m6!1s0x78e378891f9d7cb:0x8d2af01fbc28b7dc!8m2!3d-5.1009288!4d-42.8318548!15sCh1Mb2cgVmlzdG9yaWEgVmVpY3VsYXIgLSBUaW1vbpIBEnZlaGljbGVfaW5zcGVjdGlvbuABAA!16s%2Fg%2F11vcmvtd5d?entry=tts"
                target="_black"
              >
                <img
                  src="/assets/imgs/localizacao2.svg"
                  alt="icone pin do mapa"
                />
                TIMON / MA
              </S.TitleMap>
            </S.CardMap>
          </S.WrapperCards>
          <S.WrapperCards>
            <S.CardMap>
              <S.ImgMap src="/assets/imgs/map3.png" alt="foto mapa google" />
              <S.TitleMap
                href="https://www.google.com/maps/place/Log+Vistoria+Veicular/@-4.953606,-47.5058169,17z/data=!3m1!4b1!4m6!3m5!1s0x92c5f500163afe79:0x3aa3e4b93307940e!8m2!3d-4.9536113!4d-47.503242!16s%2Fg%2F11y346zjgl?entry=tts"
                target="_blank"
              >
                <img
                  src="/assets/imgs/localizacao2.svg"
                  alt="icone pin do mapa"
                />
                AÇAILANDIA / MA
              </S.TitleMap>
            </S.CardMap>
          </S.WrapperCards>

          <S.WrapperCards>
            <S.CardMap>
              <S.ImgMap src="/assets/imgs/map4.png" alt="foto mapa google" />
              <S.TitleMap
                href="https://www.google.com/maps/place/Log+Vistoria+Veicular+-+Presidente+Dutra/@-6.3059064,-47.3024188,8z/data=!4m7!3m6!1s0x7885d4a3e09710f:0xd9c1119361d378c5!8m2!3d-5.2876408!4d-44.4947759!15sCiZMb2cgVmlzdG9yaWEgVmVpY3VsYXIgcHJlc2lkZW50ZSBkdXRyYZIBFmNhcl9pbnNwZWN0aW9uX3N0YXRpb27gAQA!16s%2Fg%2F11lcr65c5d?entry=tts"
                target="_blank"
              >
                <img
                  src="/assets/imgs/localizacao2.svg"
                  alt="icone pin do mapa"
                />
                PRES. DUTRA / MA
              </S.TitleMap>
            </S.CardMap>
            <S.CardMap>
              <S.ImgMap src="/assets/imgs/map5.png" alt="foto mapa google" />
              <S.TitleMap
                href="https://www.google.com/maps/place/Log+Vistoria+Veicular/@-7.3329547,-47.4727269,17z/data=!3m1!4b1!4m6!3m5!1s0x92d82b005494bcb9:0x749a19425529c79d!8m2!3d-7.33296!4d-47.470152!16s%2Fg%2F11vrjsn8xm?entry=tts"
                target="_blank"
              >
                <img
                  src="/assets/imgs/localizacao2.svg"
                  alt="icone pin do mapa"
                />
                CAROLINA / MA
              </S.TitleMap>
            </S.CardMap>
          </S.WrapperCards>
        </S.LocalizationsContent>
      </S.SectionLocalizations>
      <S.SectionContact>
        <S.ContactContent id="contatos">
          <h2>Contatos</h2>
          <p>
            Ligue para <span>agendar</span> sua vistoria!
          </p>
        </S.ContactContent>
      </S.SectionContact>
      <S.GreenPlace>
        <S.GreenPlaceContent>
          <div>
            <S.City>São Luís / MA</S.City>
            <S.Phone>
              <img src="/assets/imgs/phone.svg" alt="icone telefone" />
              (98) 98494-3473
            </S.Phone>
          </div>

          <div>
            <S.City>Timon - Carolina</S.City>
            <S.City>Pres. Dutra - Açailândia / MA</S.City>
            <S.Phone>
              <img src="/assets/imgs/phone.svg" alt="icone telefone" />
              (99) 99233-0731
            </S.Phone>
          </div>
        </S.GreenPlaceContent>
      </S.GreenPlace>
      <Footer />
    </S.Container>
  );
};
